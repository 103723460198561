import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import './LoginPage.css';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Envoi de la requ�te de connexion � l'API JWT de live-discount.com
      const response = await fetch('https://live-discount.com/wp-json/jwt-auth/v1/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.log('R�ponse du login:', data);

      if (response.ok && data.token) {
        // Connexion r�ussie, stocker le token JWT
        localStorage.setItem('token', data.token);

        // R�cup�rer le r�le de l'utilisateur via le point de terminaison personnalis�
        const roleResponse = await fetch('https://live-discount.com/wp-json/custom/v1/user-role', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${data.token}`,
          },
        });

        const roleData = await roleResponse.json();
        console.log('R�les utilisateur:', roleData.roles);

        if (roleResponse.ok && roleData.roles && Array.isArray(roleData.roles)) {
          if (roleData.roles.includes('administrator')) {
            // Rediriger vers le tableau de bord administrateur
            navigate('/dashboard');
          } else {
            // Rediriger vers le tableau de bord des vendeurs pour les autres utilisateurs
            navigate('/vendor_dashboard');
          }
        } else {
          console.error('Impossible de r�cup�rer le r�le de l\'utilisateur:', roleData);
          alert('Une erreur est survenue lors de la r�cup�ration de votre profil.');
        }
      } else {
        console.error('�chec de la connexion', data);
        alert('Nom d\'utilisateur ou mot de passe invalide');
      }
    } catch (error) {
      // Gestion des erreurs de la requ�te
      console.error('Erreur:', error);
      alert('Une erreur est survenue. Veuillez r�essayer.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Bienvenue dans votre plateforme LIVE DISCOUNT
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Nom d'utilisateur"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Se connecter
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default LoginPage;
