// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { Home, VideoCall, History, Settings, BarChart, Add } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import io from 'socket.io-client'; // Importez le client Socket.IO

// Connectez-vous au serveur Socket.IO
const socket = io('https://srv493134.hstgr.cloud'); // Remplacez par l'URL de votre serveur

function Dashboard() {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [adminName, setAdminName] = useState('');
  const [activeMenu, setActiveMenu] = useState('Home');
  const [subMenuSelection, setSubMenuSelection] = useState('');
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('form');
  const [datenle, setDatenle] = useState('');
  const [stores, setStores] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [popupMessage, setPopupMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');

    if (token) {
      localStorage.setItem('token', token);
      navigate('/dashboard', { replace: true });
    } else {
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        navigate('/');
      }
    }

    const fetchAdminName = (token) => {
      const url = `https://live-discount.com/wp-json/wp/v2/users/me?_=${new Date().getTime()}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch admin name');
          }
          return response.json();
        })
        .then((data) => {
          if (data && data.name) {
            setAdminName(data.name === 'test seif' ? 'test seif 2' : data.name);
          } else {
            setAdminName('Admin');
          }
        })
        .catch((error) => {
          setAdminName('Admin');
        });
    };

    const fetchVendors = async () => {
      const url = `https://live-discount.com/wp-json/dokan/v1/stores`;
      const response = await fetch(url);
      const vendors = await response.json();
      setVendorList(vendors);
    };

    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      fetchAdminName(storedToken);
    }

    fetchVendors();
  }, [location, navigate]);

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
    setSubMenuSelection(''); 
    setIsSubMenuVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = 'https://live-discount.com';
  };

  const handleProfileHover = () => {
    setShowLogoutMenu(true);
  };

  const handleProfileLeave = () => {
    setShowLogoutMenu(false);
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (subMenuName) => {
    setSubMenuSelection(subMenuName);
    setActiveMenu('Analytics');
    setIsSubMenuVisible(true); 
  };

  const handleCreateLiveEventClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupContent('form');
    setDatenle('');
    setStores([]);
    setPopupMessage('');
  };

  const handlePublish = () => {
    const startTime = new Date(datenle);
    const eventDuration = 4 * 60;
    const vendorDuration = 24;
    const eventId = Date.now();

    const vendorSchedules = stores.map((store, index) => {
      const start = new Date(startTime.getTime() + index * vendorDuration * 60000);
      const end = new Date(start.getTime() + vendorDuration * 60000);
      return {
        vendorId: store,
        startTime: start.toISOString(),
        endTime: end.toISOString(),
      };
    });

    const liveLink = `https://srv493134.hstgr.cloud/live/${eventId}`;

    const liveEvent = {
      event_name: `Live Event ${eventId}`,
      start_date: startTime.toISOString(),
      end_date: new Date(startTime.getTime() + eventDuration * 60000).toISOString(),
      vendors_selected: JSON.stringify(stores),
      products_selected: JSON.stringify([]),
      live_status: 'scheduled',
      live_link: liveLink,
      created_by: 1,
    };

    console.log('Sending live event data:', liveEvent);

    // Envoyez l'�v�nement 'createLiveEvent' au serveur via Socket.IO
    socket.emit('createLiveEvent', liveEvent);

    // �coutez les �v�nements de r�ponse du serveur
    socket.on('createLiveEventSuccess', (result) => {
      console.log('Live Event created:', result);
      setPopupMessage(`Votre �v�nement en direct est disponible ici : ${liveLink}`);
      setPopupContent('confirmation');
    });

    socket.on('createLiveEventError', (err) => {
      console.error('Error creating Live Event:', err);
      setPopupMessage('Error creating Live Event. Please try again.');
    });
  };

  const renderContent = () => {
    if (activeMenu === 'Analytics') {
      return (
        <div>
          {subMenuSelection === 'Live Events' && (
            <div id="bloch3ale">Contenu des sous-�l�ments Live Events</div>
          )}
          {subMenuSelection === 'Private Lives' && (
            <div id="bloch3apl">Contenu des sous-�l�ments Private Lives</div>
          )}
          {subMenuSelection === 'Stores' && (
            <div id="bloch3as">Contenu des sous-�l�ments Stores</div>
          )}
          {!subMenuSelection && <div id="bloch3">Contenu par d�faut pour Analytics</div>}
        </div>
      );
    }

    switch (activeMenu) {
      case 'Home':
        return (
          <div id="bloch3home">
            <button className="boutoncle" onClick={handleCreateLiveEventClick}>
              <Add /> CREATE A LIVE EVENT
            </button>
            <div className="bloch3ple">Contenu bloch3ple</div>
            <div className="bloch3ppl">Contenu bloch3ppl</div>
            <div className="titretn">Today's news</div>
            <div className="bloch3total">Contenu bloch3total</div>
          </div>
        );
      case 'Live Events':
        return (
          <div id="bloch3le">
            <div className="bloch3ple">Contenu bloch3ple</div>
            <div className="titreue">Upcoming events</div>
            <div className="bloch3ue">Contenu bloch3ue</div>
            <div className="titreoe">Older events</div>
            <div className="bloch3oe">Contenu bloch3oe</div>
          </div>
        );
      case 'Private Lives':
        return (
          <div id="bloch3pl">
            <div className="bloch3prl">Contenu bloch3prl</div>
            <div className="titreueprivate">Upcoming events</div>
            <div className="bloch3ueprivate">Contenu bloch3ueprivate</div>
            <div className="titreoeprivate">Older events</div>
            <div className="bloch3oeprivate">Contenu bloch3oeprivate</div>
          </div>
        );
      case 'History':
        return <div id="bloch3history">Contenu pour l'historique des �v�nements</div>;
      case 'Control Panel':
        return <div id="bloch3cp">Contenu pour le panneau de configuration</div>;
      default:
        return <div id="bloch3">Contenu par d�faut</div>;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="bloc1">
        <div className="logo-container">
          <img
            src="https://live-discount.com/wp-content/uploads/2023/10/Logo_live_discount-1024x782.png"
            alt="Logo"
            className="logo"
          />
        </div>
        <ul className="menu1">
          <li className={activeMenu === 'Home' ? 'active' : ''} onClick={() => handleMenuClick('Home')}>
            <Home />
            Home
          </li>
          <li
            className={activeMenu === 'Live Events' ? 'active' : ''}
            onClick={() => handleMenuClick('Live Events')}
          >
            <VideoCall />
            Live Events
          </li>
          <li
            className={activeMenu === 'Private Lives' ? 'active' : ''}
            onClick={() => handleMenuClick('Private Lives')}
          >
            <VideoCall />
            Private Lives
          </li>
          <li
            className={activeMenu === 'History' ? 'active' : ''}
            onClick={() => handleMenuClick('History')}
          >
            <History />
            History
          </li>
          <li
            className={activeMenu === 'Control Panel' ? 'active' : ''}
            onClick={() => handleMenuClick('Control Panel')}
          >
            <Settings />
            Control Panel
          </li>
          {/* Ajout de Analytics avec le sous-menu */}
          <li
            className={activeMenu === 'Analytics' ? 'active' : ''}
            onMouseEnter={handleSubMenuToggle}
            onMouseLeave={handleSubMenuToggle}
          >
            <BarChart />
            Analytics
            {isSubMenuVisible && (
              <ul className="submenu">
                <li onClick={() => handleSubMenuClick('Live Events')}>Live Events</li>
                <li onClick={() => handleSubMenuClick('Private Lives')}>Private Lives</li>
                <li onClick={() => handleSubMenuClick('Stores')}>Stores</li>
              </ul>
            )}
          </li>
        </ul>
        <button className="bouton1" onClick={() => (window.location.href = 'https://live-discount.com/')}>
          GO TO DASHBOARD
        </button>
      </div>

      <div className="bloc2">
        <div className="bloch1">
          <span className="admin-name">{adminName}</span>

          <span
            className="admin-icon"
            onMouseEnter={handleProfileHover}
            onMouseLeave={handleProfileLeave}
          >
            <img
              src="https://img.icons8.com/?size=100&id=7820&format=png&color=000000"
              alt="Admin Icon"
            />
            {showLogoutMenu && (
              <div className="admin-menu">
                <a href="#" className="logout-btn" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            )}
          </span>

          <span className="notifications-icon">
            <img src="https://img.icons8.com/material-outlined/24/alarm.png" alt="Notifications" />
          </span>
        </div>

        <div className="bloch2">
          <span className="welcome-text">
            Welcome, <strong style={{ color: '#7fd2f3' }}>{adminName}</strong>
          </span>
        </div>

        <div className="bloch3">{renderContent()} </div>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popupnle">
            {popupContent === 'form' ? (
              <>
                <div className="popup-title">New live event</div>
                <form className="formnle">
                  <label htmlFor="datenle">Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="datenle"
                    value={datenle}
                    onChange={(e) => setDatenle(e.target.value)}
                  />
                  <label htmlFor="stores">Select Stores:</label>
                  <select
                    id="stores"
                    multiple
                    value={stores}
                    onChange={(e) =>
                      setStores(Array.from(e.target.selectedOptions, (option) => option.value))
                    }
                  >
                    {vendorList.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.store_name}
                      </option>
                    ))}
                  </select>
                </form>
                <div className="popup-actions">
                  <button className="popup-delete" onClick={handlePopupClose}>
                    Delete
                  </button>
                  <button className="popup-publish" onClick={handlePublish}>
                    Publish
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="popup-title">New live</div>
                <p>{popupMessage}</p>
                <button className="popup-close" onClick={handlePopupClose}>
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;